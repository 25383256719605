/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios                             from 'axios';
window.axios = axios;
import Cookies                                                     from 'js-cookie';
import { setSpinnerOff, setSpinnerOn, showAlert } from "./utils";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// let token = Cookies.get('XSRF-TOKEN');
// window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
// let token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');
// axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

// Include CSRF token as a header based on the value in the "XSRF" token cookie.
axios.interceptors.request.use(config => {
    // Turn on the spinner
    setSpinnerOn();

    config.headers['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    return config;
});

axios.interceptors.response.use(response => {
    // Turn off the spinner
    setSpinnerOff('all');

    if (response.data !== undefined && response.data.Result !== undefined && response.data.Result.csrf_token !== undefined) {
        // When we make a POST call, a new csrf token should always be generated. Update the application pages with the
        //  new token.
        document.querySelectorAll('form input[name="_token"]').forEach(function(_token) {
            _token.value = response.data.Result.csrf_token;
        });

        // Update the CSRF token in the meta tag
        document.querySelector('meta[name="csrf-token"]').setAttribute('content', response.data.Result.csrf_token);
    }

    const token = response.headers['x-xsrf-token'];
    if (token) {
        // Set the Axios default header with the new token
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

        // Update the CSRF token in the meta tag
        document.querySelector('meta[name="csrf-token"]').setAttribute('content', token);
    }
    else {
        // Check if the header contains the CSRF token
        let newToken = Cookies.get('XSRF-TOKEN');
        if ( newToken ) {

            // Set the Axios default header with the new token
            axios.defaults.headers.common['X-CSRF-TOKEN'] = newToken;

            // Update the CSRF token in the meta tag
            // DO NOT DO THIS!
            // document.querySelector('meta[name="csrf-token"]').setAttribute('content', newToken);
        }
    }

    return response;
}, error => {
    // Turn off the spinner
    try {
        if ( error.response !== undefined ) {
            setSpinnerOff('all');

            if ( error.response.status === 500 ) {
                // We encountered a system-wide error.
                showAlert('We encountered a system error:<br>' + error.response.data.message, 'System Error');

                // An error message should have been emailed to the Admins before this code hits.
            }
            else if ( error.response.status === 422 ) {}
        }
        else if ( error.message === 'canceled' ) {}
        else {
            setSpinnerOff('all');
            console.log(error);
        }
    }
    catch(err) {
        setSpinnerOff('all');
        console.log(error);
        showAlert("Missing Status. Check the console.");
    }

    return Promise.reject(error);
});

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });
