import { addListener, getAutocomplete } from "../../../../../../resources/js/utils";
import {BRDropdown}    from "@dbetts/brjstools";
import route           from "ziggy-js";

class GlobalSearch
{
    static globalSearchInstances = new Map();
    constructor()
    {
        this.setGlobalDropdownData = this.setGlobalDropdownData.bind(this);

        const globalSearch = document.querySelector('[data-br-dropdown="globalSearch"]');
        if (globalSearch && GlobalSearch.getInstance(globalSearch) === undefined ) {
            new BRDropdown(globalSearch, {
                openOn: 'none',
            });
            GlobalSearch.globalSearchInstances.set(globalSearch, this);

            addListener('keyup', '#globalSearch', (evt) => {
                // Determine if the dropdown is initialized. If not, create it. If it's not open, open it.
                let searchBar = evt.currentTarget;
                this.globalSearch(searchBar);
            });
        }
    }

    globalSearch(searchBar)
    {
        if ( searchBar.value.length > 2 || searchBar.value.length === 0 ) {
            this.debouncedSearch(searchBar);
        }
    }

    debouncedSearch = this.debounce((searchBar) => {
        const pageURL = this._getProductsPageData(searchBar);
        getAutocomplete(pageURL)
            .then(res => {
                if ( res.Status === 'Success' ) {
                    // Render the product list in a dropdown box.
                    this.setGlobalDropdownData(res.Result);
                }
                else {
                    console.log(res);
                }
            }).catch(error => {
            if ( error.message !== 'canceled' )
                console.log(error)
        });
    }, 300);

    debounce(func, delay)
    {
        let debounceTimer;
        return (...args) => {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func(...args), delay);
        }
    }


    _getProductsPageData(searchBar)
    {
        let requestParams = {
            search_term: searchBar.value || undefined,
            global_search: 1,
        };

        const axiosInstance = axios.create(
            {
                baseURL: route('products.search')
            }
        );

        return axiosInstance.getUri({params: requestParams});
    }

    setGlobalDropdownData(dataResult)
    {
        const globalSearch = document.querySelector('[data-br-dropdown="globalSearch"]');
        const minWidth = globalSearch.offsetWidth;
        const brDropdown = BRDropdown.getInstance(globalSearch);

        // Add the background
        if ( brDropdown.brView.nextElementSibling === null || brDropdown.brView.nextElementSibling.getAttribute('data-br-backdrop') === null ) {
            const newElement = document.createElement('div');
            newElement.setAttribute('class', 'opacity-50 transition-all duration-300 ease-in-out fixed top-0 left-0 z-20 bg-black w-screen h-screen');
            newElement.setAttribute('data-br-backdrop', '');
            brDropdown.brView.insertAdjacentElement('afterend', newElement);
        }

        // Show the dropdown
        brDropdown.setBody( dataResult.product_list );
        brDropdown.brView.style.minWidth = `${minWidth}px`;

        // When a link is clicked, the brDropdown should close.
        addListener('click', brDropdown.brView.querySelectorAll('a'), () => brDropdown.hide());
        addListener('click', brDropdown.brView.querySelectorAll('[data-btn-global-search-cancel]'), () => {
            globalSearch.value = '';
            brDropdown.hide()
        });

        brDropdown.show({currentTarget:globalSearch});
    }

    static getInstance(searchElement) {
        return GlobalSearch.globalSearchInstances.get(searchElement);
    }
}

export default GlobalSearch