import { animateInput }                 from "./modules/utilities";
import GlobalSearch                     from "./class/widgets/GlobalSearch";
import AuthHeader                       from "./class/widgets/AuthHeader";
import Footer                           from "./class/widgets/Footer";
import {BRDropdown}                     from "@dbetts/brjstools";

window.addEventListener('load', function() {

    new GlobalSearch();
    new AuthHeader();
    new Footer();

    BRDropdown.initAll();

    animateInput('email', document);
    animateInput('password', document);
});