import route                                                                     from "ziggy-js";
import { animateInput, setCartHeaderData, setSavedHeaderMarker }                 from "../../modules/utilities";
import {
    addListener,
    getRandomSpinId,
    htmlToElement,
    popupToast, setModal,
    showAlert
} from "../../../../../../resources/js/utils";
import {BRModal, BRCollapse, BRCarouselStatic}                                   from "@dbetts/brjstools";
import AuthHeader                                                                from "../widgets/AuthHeader";

const ProductAction = {
    addToCart(e)
    {
        let btn = e.currentTarget;
        let productId = btn.getAttribute('data-id');
        let quantity = 1;
        if ( btn.previousElementSibling !== null && btn.previousElementSibling.querySelector('[data-quanity-value]') !== null )
            quantity = btn.previousElementSibling.querySelector('[data-quanity-value]').textContent.trim();

        axios.post(route('cart.add', {productId: productId}),
                   {
                       'quantity': quantity,
                   }
        )
            .then(res => {
                let data = res.data;
                if ( data.Status === 'Success' ) {
                    // Always reload the Cart data in the header.
                    let total = data.Result.shoppingCart.total;
                    let items = data.Result.shoppingCart.items;
                    setCartHeaderData(total, items);

                    popupToast(`<div class="flex items-center">
                                      <svg class="ml-3 mr-4" xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512">
                                          <path d="M447.9 142.5l-23.2 22L181 395.3l-22 20.8-22-20.8L23.2 287.6 0 265.6l44-46.5 23.2 22L159 328 380.7 118l23.2-22 44 46.5z" fill="#F1F1EF"/>
                                      </svg>
                                      <span class="text-ops-smoke x-sm:min-w-32">This item has been added to the cart.</span>
                                      <a href="${route('cart.view')}" class="ml-8 mr-12 px-6 py-2 text-base font-semibold 
                                   text-center text-ops-gunmetal cursor-pointer bg-ops-goldenrod-500 hover:brightness-125 
                                   transition-all uppercase whitespace-nowrap x-sm:ml-4 x-sm:mr-0">View Cart</a>
                                   </div>`, undefined, 'cart', 5);

                    // Set the icon
                    if ( btn.tagName === 'SPAN' ) {
                        btn.classList.add('text-ops-goldenrod-500');
                        let svg = btn.children[0];
                        svg.classList.add('fa-sharp');
                        svg.classList.add('fa-solid');
                    }
                    else if ( btn.closest('[data-saved-item]') !== null ) {
                        // Remove the item from the Saved Items list.
                        btn.closest('[data-saved-item]').remove();
                    }

                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(error => {
                console.error(error)
            });
    },

    removeSaved(e)
    {
        let btn = e.currentTarget;
        let productId = btn.getAttribute('data-id');

        axios.post(route('cart.save-remove', {productId: productId}),
                   {}
        )
            .then(res => {
                let data = res.data;
                if ( data.Status === 'Success' ) {

                    if ( btn.closest('#productGrid') !== null ) {
                        // Remove the marker from the product grid list.
                        let lightBookmk = '<i class="fa-sharp fa-light fa-bookmark mx-1 cursor-pointer hover:text-ops-primary" title="Save for later"></i>';
                        btn.classList.remove('text-ops-primary');
                        btn.children[0].replaceWith(htmlToElement(lightBookmk));
                    }
                    else {
                        // Remove the item from the Saved Items list in the Profile pages.
                        btn.closest('[data-saved-item]').remove();
                    }
                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(error => {
                console.error(error)
            });
    },

    popupItem(e)
    {
        let btn = e.currentTarget;
        axios.get(route('products.quick-view'), {
                      params: {
                          'pid': btn.getAttribute('data-id')
                      },
                  }
        )
            .then(res => {
                let data = res.data;
                if ( data.Status === 'Success' ) {
                    // Set and populate the Modal
                    const modalEl = document.querySelector('[data-br-modal="default"]').cloneNode(true);
                    modalEl.setAttribute('id', 'SPIN_' + getRandomSpinId());
                    const body = document.querySelector('body');
                    body.appendChild(modalEl);
                    const brModal = new BRModal(modalEl);

                    brModal.setTitle('');
                    brModal.setBody(data.Result.view);
                    brModal.hideFooter();

                    brModal.brModalBody.classList.remove('p-12');
                    brModal.brModalBody.classList.add('pt-0');
                    brModal.brModalBody.children[0].classList.remove('mt-4');
                    brModal.brModalBody.parentElement.classList.remove('max-w-[550px]');
                    brModal.brModalBody.parentElement.classList.add('w-[64em]');
                    brModal.brModalBody.parentElement.classList.add('max-w-[90%]');

                    // Set the listeners for the popup
                    addListener('click', modalEl.querySelector('.addToCart'), ProductAction.addToCart);
                    addListener('click', modalEl.querySelector('.saveForLater'), ProductAction.saveForLater);
                    addListener('click', modalEl.querySelector('.shareLink'), ProductAction.shareLink);
                    addListener('click', modalEl.querySelector('.quantityDecrease'), ProductAction.preCartQuantityChange);
                    addListener('click', modalEl.querySelector('.quantityIncrease'), ProductAction.preCartQuantityChange);

                    brModal.show();
                    BRCarouselStatic.initAll(modalEl);
                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(error => {
                console.error(error)
            });
    },

    shareLink(e)
    {
        let btn = e.currentTarget;
        let productId = btn.getAttribute('data-id');
        let title = document.title.split(' - ').pop().trim();

        function copyToClipboard()
        {
            let url = route('products.view') + '?pid=' + productId;
            navigator.clipboard.writeText(url)
                .then(() => {
                    // Show a message like 'Link copied to clipboard!'
                    popupToast('The link to this product has been copied to your clipboard.', 'Product Copied');
                })
                .catch(err => {
                    console.error('Error in copying text: ', err);
                });
        }

        if ( navigator.share ) {
            navigator.share(
                {
                    title: title,
                    url: route('products.view') + '?pid=' + productId
                }
            ).then(() => {
                console.log('Thanks for sharing!');
            })
                .catch(console.error);
        }
        else {
            // Fallback for devices that don't support the Web Share API
            copyToClipboard();
        }
    },

    saveForLater(e)
    {
        // If the btn icon is solid-goldenrod, the send the script to removeSaved()
        let btn = e.currentTarget;
        if ( btn.classList.contains('text-ops-primary') ) {
            if ( btn.children[0].getAttribute('data-prefix') === 'fass' ) {
                ProductAction.removeSaved.call(btn);
                return false;
            }
        }

        let productId = btn.getAttribute('data-id');

        axios.post(route('cart.save', {
            productId: productId,
            quantity: 1
        }))
            .then(function(res) {
                let data = res.data;
                if ( data.Status === 'Success' ) {

                    if ( data.Result.view !== undefined ) {
                        // Ask about logging in or creating an account.
                        let modalEl = document.getElementById('loginModal');
                        if ( modalEl === null ) {
                            let body = document.querySelector('body');
                            body.append(htmlToElement(data.Result.view));
                        }
                        else {
                            modalEl.parentElement.replaceChild(htmlToElement(data.Result.view), modalEl);
                        }
                        modalEl = document.getElementById('loginModal');
                        let brModal = BRModal.getInstance(modalEl);
                        if (!brModal) {
                            brModal = new BRModal(modalEl);
                        }

                        brModal.setTitle("You're Not Logged In");

                        // let modalTitle = modalEl.querySelector('.modalTitle');
                        // modalTitle.innerHTML = "You're Not Logged In";

                        let titleDesc = document.createElement('div');
                        titleDesc.setAttribute('class', 'mx-8 mt-2');
                        titleDesc.innerHTML = "To ensure this product is saved once your browser session ends, kindly log in or create an account.";
                        // let reference = modalTitle.parentElement;
                        // let parent = reference.parentElement;

                        // parent.insertBefore(titleDesc, reference.nextElementSibling);
                        brModal.setBetweenTitleBody(titleDesc);

                        // Set the input fields
                        animateInput('customer_email', modalEl);
                        animateInput('customer_password', modalEl);


                        // Set the Enter button key to post the login form.
                        let authHeader = AuthHeader.getInstance(document.querySelector('[data-br-dropdown="authenticated"]'));
                        if (!authHeader) {
                            authHeader = new AuthHeader();
                        }

                        let forgotBtn = modalEl.querySelector('#forgotPassword');
                        addListener('click', forgotBtn, authHeader.forgotPassword);

                        let createBtn = modalEl.querySelector('#createAccount');
                        addListener('click', createBtn, authHeader.createAccount);

                        let loginBtn = modalEl.querySelector('#userLogin');
                        addListener('click', loginBtn, authHeader.userLogin);

                        let ignoreBtn = modalEl.querySelector('#ignoreLogin');
                        addListener('click', ignoreBtn, authHeader.ignoreLogin, {btn: btn});

                        addListener('shown.te.modal', modalEl, authHeader.setSubmitLogin, {btn: loginBtn});

                        forgotBtn.setAttribute('data-caller', 'saveForLater_' + productId);
                        createBtn.setAttribute('data-caller', 'saveForLater_' + productId);
                        loginBtn.setAttribute('data-caller', 'saveForLater_' + productId);

                        // Show the modal.
                        brModal.show();

                        modalEl.querySelector('[name="customer_email"]').focus();
                    }
                    else {
                        // Set the icon
                        let solidBookmk = '<i class="fa-sharp fa-solid fa-bookmark mx-1 cursor-pointer hover:text-ops-primary" title="Save for later"></i>';
                        btn.classList.add('text-ops-primary');
                        btn.children[0].replaceWith(htmlToElement(solidBookmk));

                        // Add the Marker in the Header
                        if ( data.Result.hasSaved !== undefined && data.Result.hasSaved === true ) {
                            setSavedHeaderMarker(data.Result.hasSaved);
                        }
                    }
                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(function(error) {

                if ( error.response !== undefined ) {
                    console.log(error);
                }
                else {
                    console.log(error);
                }
            });
    },

    preCartQuantityChange(e)
    {
        let button = e.currentTarget;
        let operation = 'increase';
        if ( button.classList.contains('quantityDecrease') )
            operation = 'decrease';

        let quantityValue = button.parentElement.children[1]

        let qtyValue = quantityValue.innerText.trim();
        if ( operation === 'increase' ) {
            qtyValue = parseInt(qtyValue, 10) + 1
        }
        else if ( operation === 'decrease' ) {
            qtyValue = Math.max(parseInt(qtyValue, 10) - 1, 1)
        }

        quantityValue.innerText = qtyValue;
    },

    notifyWhenInStock(e)
    {
        let btn = e.currentTarget;
        let productId = btn.getAttribute('data-id');

        const brModal = setModal();
        brModal.hideFooter();

        brModal.setTitle('OUT OF STOCK');
        let bodyHTML = '<p class="text-sm text-gray-600 my-6">We want this product back in stock as much as you do. ' +
            'To be the first to know when it’s available, leave your email below:</p>' +
            '    <div class="space-y-4">' +
            '      <input type="text" name="name" placeholder="Name" class="w-full px-4 py-2 border border-ops-border focus:outline-none focus:ring-1 focus:ring-ops-primary focus:border-transparent" />' +
            '      <input type="email" name="email" placeholder="Email" class="w-full px-4 py-2 border border-ops-border focus:outline-none focus:ring-1 focus:ring-ops-primary focus:border-transparent" />' +
            '      <button type="button" class="btn-primary-lg" data-id="' + productId + '">Notify Me</button>' +
            '    </div>';
        brModal.setBody(bodyHTML);

        // Set the listeners for the popup
        addListener('click', brModal.brModal.querySelector('button'), ProductAction._submitStockNotification);

        brModal.show();
    },

    _submitStockNotification(e)
    {
        let btn = e.currentTarget;
        let productId = btn.getAttribute('data-id');
        let email = btn.previousElementSibling;
        let name = email.previousElementSibling;

        if (name.value === '' || email.value === '') {
            updateClassList(email, email.value === '');
            updateClassList(name, name.value === '');
        }
        else {
            axios.post(route('products.notify_oos'), {
                name: name.value,
                email: email.value,
                product_id: productId,
            })
                .then(function(res) {
                    let data = res.data;
                    if ( data.Status === 'Success' ) {
                        BRModal.closeModal('#defaultModal');
                    }
                    else {
                        showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                    }
                })
                .catch(function(error) {
                    console.log(error);

                    BRModal.closeModal('#defaultModal');
                });
        }

        function updateClassList(element, isEmpty) {
            element.classList.remove('border-ops-border');
            element.classList[isEmpty ? 'add' : 'remove']('border-danger');
        }
    },

    setAllProductListeners()
    {
        addListener('click', '.notifyStock', ProductAction.notifyWhenInStock);
        addListener('click', '.popupItem', ProductAction.popupItem);
        addListener('click', '.addToCart', ProductAction.addToCart);
        addListener('click', '.saveForLater', ProductAction.saveForLater);
        addListener('click', '.shareLink', ProductAction.shareLink);
        addListener('click', '.reCategorize', ProductAction.openReCategorize);
    },

    validateQtyChange()
    {
        addListener('click', '.quantityDecrease', ProductAction.preCartQuantityChange);
        addListener('click', '.quantityIncrease', ProductAction.preCartQuantityChange);
    },


    //####################//
    //#### Admin Only ####//
    //####################//
    openReCategorize(e)
    {
        let btn = e.currentTarget;
        axios.get(route('admin.re-categorize'), {
                      params: {
                          'product_id': btn.getAttribute('data-id')
                      },
                  }
        )
            .then(res => {
                let data = res.data;
                if ( data.Status === 'Success' ) {
                    // Set and populate the Modal
                    let brModal = setModal();

                    brModal.setTitle('Re-Categorize Product');
                    brModal.setBody(data.Result);

                    BRCollapse.initAll(brModal.brModal);

                    addListener('click', '.reCatMap', ProductAction._reCategorize);
                    addListener('click', '.regenerateTitle', ProductAction._regenerateTitle);
                    addListener('click', '.approveTitle', ProductAction._approveTitle);
                    addListener('click', '.closeModal', () => brModal.hide());
                    addListener('keyup', '#titleUpdate', ProductAction._showApproveBtn);

                    brModal.show();
                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(error => {
                console.error(error)
            });
    },

    _reCategorize(e)
    {
        let btn = e.currentTarget;
        let categoryId = btn.getAttribute('data-category-id');
        let productId = document.getElementById('defaultModal').querySelector('.modalBody').querySelector('[data-product-id]').getAttribute('data-product-id');

        axios.post(route('admin.re-categorize'),
                   {
                       'product_id': productId,
                       'category_id': categoryId,
                   }
        )
            .then(res => {
                let data = res.data;
                if ( data.Status === 'Success' ) {

                    // Close the modal
                    let modalEl = document.getElementById('defaultModal');
                    let brModal = BRModal.getInstance(modalEl);
                    brModal.hide();
                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(error => {
                console.error(error)
            });
    },

    _regenerateTitle(e)
    {
        let btn = e.currentTarget;
        let productId = btn.getAttribute('data-id');

        axios.post(route('admin.regenTitle'),
                   {
                       'product_id': productId,
                   }
        )
            .then(res => {
                let data = res.data;
                if ( data.Status === 'Success' ) {
                    // Show the new item name
                    btn.parentElement.parentElement.previousElementSibling.value = data.Result;
                    // Show the Approve button
                    btn.nextElementSibling.classList.remove('hidden');
                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(error => {
                console.error(error)
            });
    },

    _showApproveBtn(e)
    {
        let textarea = e.currentTarget;

        // Show the Approve button
        textarea.nextElementSibling.querySelector('.approveTitle').classList.remove('hidden');
    },

    _approveTitle()
    {
        let btn = this;
        let title = btn.parentElement.parentElement.previousElementSibling.value;
        let productId = btn.getAttribute('data-id');

        axios.post(route('admin.approveTitle'),
                   {
                       product_id: productId,
                       product_title: title
                   }
        )
            .then(res => {
                let data = res.data;
                if ( data.Status === 'Success' ) {

                    // Close the modal
                    let modalEl = document.getElementById('defaultModal');
                    const brModal = BRModal.getInstance(modalEl);

                    brModal.hide();
                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(error => {
                console.error(error)
            });
    },


}

export default ProductAction;