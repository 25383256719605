import { addListener, htmlToElement, showAlert } from "../../../../../../resources/js/utils";
import { BRDropdown, BRModal }                   from "@dbetts/brjstools";
import route                                     from "ziggy-js";
import {
    animateInput,
    animateSelect,
    setCartHeaderData,
    setSavedHeaderMarker
}                                                from "../../modules/utilities";
import ProductAction                             from "../mixin/ProductAction";
import zxcvbn                                    from "zxcvbn";

class AuthHeader
{
    static authInstances = new Map();
    constructor()
    {
        const authElement = document.querySelector('[data-br-dropdown="authenticated"]');
        if (authElement && AuthHeader.getInstance(authElement) === undefined ) {

            new BRDropdown(authElement);
            AuthHeader.authInstances.set(authElement, this);
        }
        else {
            this.init();
        }
    }
    init()
    {
        addListener('click', '[data-openLogin]', (evt) => this.openLogin(evt));
        addListener('click', '#userLogin', (evt) => this.userLogin(evt));
        addListener('click', '[data-openRegister]', () => this.openRegister());
    }

    openLogin(evt)
    {
        let btn = evt.currentTarget;
        let caller = null;
        if ( btn !== undefined )
            caller = btn.getAttribute('data-caller');

        // Show a modal popup to login.
        axios.get(route('login'), {
            params: {},
        })
            .then((res) => {
                let data = res.data;
                if ( data.Status === 'Success' ) {

                    let modalEl = document.getElementById('loginModal');
                    if ( modalEl === null ) {
                        let body = document.querySelector('body');
                        body.append(htmlToElement(data.Result.view));
                    }
                    else {
                        let modal = BRModal.getInstance(modalEl);
                        modal.dispose();

                        modalEl.parentElement.replaceChild(htmlToElement(data.Result.view), modalEl);
                    }
                    modalEl = document.getElementById('loginModal');

                    const brModal = new BRModal(modalEl);

                    // Set the Enter button key to post the login form.
                    let forgotBtn = modalEl.querySelector('#forgotPassword');
                    brModal.setListener('click', forgotBtn, (evt) => this.forgotPassword(evt));

                    let createBtn = modalEl.querySelector('#createAccount');
                    brModal.setListener('click', createBtn, (evt) => this.createAccount(evt));

                    let loginBtn = modalEl.querySelector('#userLogin');
                    brModal.setListener('click', loginBtn, (evt) => this.userLogin(evt));

                    // addListener('shown.te.modal', modalEl, setSubmitLogin);
                    brModal.setListener('keyup', '#loginForm', (evt) => {
                        if ( evt.key === 'Enter' ) {
                            evt.preventDefault();
                            this.userLogin(evt, loginBtn);
                        }
                    });

                    // Set the animated input fields
                    animateInput('customer_email', modalEl);
                    animateInput('customer_password', modalEl);

                    // Show the modal.
                    brModal.show();

                    modalEl.querySelector('[name="customer_email"]').focus();

                    if ( caller !== null ) {
                        forgotBtn.setAttribute('data-caller', caller);
                        createBtn.setAttribute('data-caller', caller);
                        loginBtn.setAttribute('data-caller', caller);
                    }
                }
                else {
                    showAlert(data.Message);
                }
            })
            .catch(function(error) {
                console.log(error)
            });
    }

    userLogin(evt, loginBtn)
    {
        let btn = loginBtn || evt.currentTarget;
        let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let email = btn.parentElement.parentElement.querySelector('[name="customer_email"]');
        let passwd = btn.parentElement.parentElement.querySelector('[name="customer_password"]');
        let caller = btn.getAttribute('data-caller');
        axios.post(route('login'), {
            email: email.value,
            password: passwd.value,
            timezone: userTimezone,
            caller: caller,
        })
            .then((res) => {
                let data = res.data;
                if ( data.Status === 'Success' ) {
                    // Populate data returned by the script
                    if ( caller === 'shippingAddress' ) {
                        // Update the contact and shipping information on the page.

                        let contactDom = document.getElementById('contactSec');
                        contactDom.innerHTML = data.Result.res.contact;

                        let addressDom = document.getElementById('addressSec');
                        addressDom.replaceChild(htmlToElement(data.Result.res.address), addressDom.children[1]);
                        addressDom.children[0].append(htmlToElement(data.Result.res.addressChoice));

                        // Remove the login request section
                        contactDom.previousElementSibling.remove();

                        animateInput('company', addressDom);
                        animateInput('address', addressDom);
                        animateInput('suite', addressDom);
                        animateInput('city', addressDom);
                        animateInput('zip', addressDom);
                        animateSelect('state', addressDom)
                        // animateSelect('addr_country', addressDom)

                        animateInput('first_name', contactDom);
                        animateInput('last_name', contactDom);
                        animateInput('contact_email', contactDom);
                        animateInput('phone', contactDom);
                    }

                    // Always reload the Cart data in the header.
                    let total = data.Result.shoppingCart.total;
                    let items = data.Result.shoppingCart.items;
                    setCartHeaderData(total, items);
                    this.setLoginHeaderData(data.Result.authHeader);

                    // Add the Marker in the Header
                    if (data.Result.hasSaved !== undefined && data.Result.hasSaved === true) {
                        setSavedHeaderMarker( data.Result.hasSaved );
                    }

                    let loginModal = document.getElementById('loginModal');
                    let modal = BRModal.getInstance(loginModal);
                    modal.hide();
                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(function(error) {

                if ( error.response !== undefined ) {
                    if ( error.response.status === 422 ) {
                        let errors = error.response.data.errors;
                        for ( let field in errors ) {
                            if ( errors.hasOwnProperty(field) ) {
                                switch ( field ) {
                                    case 'email':
                                        email.parentElement.nextElementSibling.innerHTML = errors[field][0];
                                        email.parentElement.nextElementSibling.classList.remove('hidden');
                                        break;
                                    case 'password':
                                        passwd.parentElement.nextElementSibling.innerHTML = errors[field][0];
                                        passwd.parentElement.nextElementSibling.classList.remove('hidden');
                                        break;
                                }
                            }
                        }
                    }
                    else {
                        console.log(error);
                    }
                }
                else {
                    console.log(error);
                }
            });
    }

    setLoginHeaderData(authWidget)
    {
        document.getElementById('accountList').replaceWith(htmlToElement(authWidget));

        const ddElement = document.querySelector('[data-br-dropdown="authenticated"]');
        if ( BRDropdown.getInstance(ddElement) === undefined ) {
            new BRDropdown(ddElement);
        }
    }

    forgotPassword(evt)
    {
        let btn = evt.currentTarget;
        let caller = btn.getAttribute('data-caller');

        // Replace the current modal with new .modalBody
        axios.get(route('password.send'), {
            params: {},
        })
            .then((res) => {
                let data = res.data;
                if ( data.Status === 'Success' ) {

                    let modalEl = document.getElementById('loginModal');
                    if ( modalEl === null ) {
                        modalEl = document.getElementById('defaultModal');
                    }

                    const brModal = new BRModal(modalEl);

                    modalEl.querySelector('.modalTitle').innerHTML = 'Reset Password';
                    modalEl.querySelector('.modalBody').innerHTML = data.Result.view;

                    brModal.show();

                    addListener('click', '#sendResetLink', () => this.sendResetLink());
                    addListener('click', '#closeModal', () => { brModal.hide() } );

                    animateInput('reset_email', modalEl);
                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(error => {
                if ( error.message !== 'canceled' ) {
                    console.error(error)
                }
            });
    }

    createAccount(evt)
    {
        let btn = evt.currentTarget;
        let caller = btn.getAttribute('data-caller');

        // Replace the current modal with new .modalBody
        axios.get(route('account.create'), {
            params: {},
        })
            .then((res) => {
                let data = res.data;
                if ( data.Status === 'Success' ) {

                    let modalEl = document.getElementById('loginModal');
                    let modal = BRModal.getInstance(modalEl);
                    modal.dispose();

                    const brModal = new BRModal(modalEl);
                    brModal.setTitle('Create Account');
                    brModal.setBody(data.Result);


                    let createBtn = modalEl.querySelector('#createUser');
                    brModal.setListener('click', createBtn, (evt) => this.createUser(evt));
                    createBtn.setAttribute('data-caller', caller);

                    modalEl.querySelectorAll('.showPassword').forEach((sho) => {
                        brModal.setListener('click', sho, (evt) => this.showPassword(evt));
                    });
                    brModal.setListener('keyup', '#password', (evt) => this.validatePasswordStrength(evt));

                    brModal.setListener('keyup', '#createForm', (evt) => {
                        if ( evt.key === 'Enter' ) {
                            evt.preventDefault();
                            this.createUser(evt, createBtn);
                        }
                    });

                    animateInput('first_name', modalEl);
                    animateInput('last_name', modalEl);
                    animateInput('contact_email', modalEl);
                    animateInput('password', modalEl);
                    animateInput('password_confirmation', modalEl);


                    // Show the modal.
                    brModal.show();

                    modalEl.querySelector('[id="first_name"]').focus();
                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(error => {
                if ( error.message !== 'canceled' ) {
                    console.error(error)
                }
            });
    }

    sendResetLink()
    {
        let email = document.getElementById('reset_email');
        if ( email.value !== '' ) {
            axios.post(route('password.send'), {
                email: email.value,
            })
                .then((res) => {
                    let data = res.data;
                    if ( data.Status === 'Success' ) {

                        let modalEl = document.getElementById('loginModal');
                        if (modalEl === null)
                            modalEl = document.getElementById('defaultModal');

                        let brModal = BRModal.getInstance(modalEl);

                        if ( data.Result.errors !== undefined ) {
                            for ( let field in data.Result.errors ) {
                                if ( data.Result.errors.hasOwnProperty(field) ) {
                                    if ( field === 'email' ) {
                                        let errBox = modalEl.querySelector('#reset_email').parentElement.nextElementSibling;
                                        errBox.innerHTML = data.Result.errors[field];
                                        errBox.classList.remove('hidden');
                                    }
                                }
                            }

                            modalEl.querySelector('#reset_email').focus();
                        }
                        else {
                            modalEl.querySelector('.modalBody').innerHTML = data.Result.view;

                            brModal.setListener('click', '#closeModal', brModal.hide);
                        }
                    }
                    else {
                        showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                    }
                })
                .catch(function(error) {

                    console.log(error);
                });
        }
    }

    openRegister()
    {
        // Show a modal popup to login.
        axios.get(route('login.register'), {
            params: {},
        })
            .then((res) => {
                let data = res.data;
                if ( data.Status === 'Success' ) {

                    let modalEl = document.getElementById('loginModal');
                    if ( modalEl === null ) {
                        let body = document.querySelector('body');
                        body.append(htmlToElement(data.Result.view));
                    }
                    else {
                        let modal = BRModal.getInstance(modalEl);

                        modalEl.parentElement.replaceChild(htmlToElement(data.Result.view), modalEl);

                        modal.dispose();
                    }
                    modalEl = document.getElementById('loginModal');

                    const brModal = new BRModal(modalEl);
                    brModal.setTitle('Create Account');


                    // Set the Enter button key to post the login form.
                    let createBtn = modalEl.querySelector('#createUser');
                    brModal.setListener('click', createBtn, (evt) => this.createUser(evt));

                    modalEl.querySelectorAll('.showPassword').forEach((sho) => {
                        brModal.setListener('click', sho, (evt) => this.showPassword(evt));
                    });
                    brModal.setListener('keyup', '#password', (evt) => this.validatePasswordStrength(evt));

                    brModal.setListener('keyup', '#createForm', function(e) {
                        if ( e.keyCode === 13 ) {
                            e.preventDefault();
                            createUser.call(createBtn);
                        }
                    });

                    // Set the form input listeners
                    animateInput('first_name', modalEl);
                    animateInput('last_name', modalEl);
                    animateInput('contact_email', modalEl);
                    animateInput('password', modalEl);
                    animateInput('password_confirmation', modalEl);

                    /* ToDo: Do we care to do this?
                     let fn = modalEl.querySelector('#first_name');
                     fn.focus();
                     */


                    // Create and show the modal.
                    /*let modal = new Modal(modalEl, {
                     backdrop: 'static'
                     });*/
                    brModal.show();
                }
                else {
                    showAlert(data.Message);
                }
            })
            .catch(function(error) {
                console.log(error)
            });
    }

    createUser(evt, createBtn)
    {
        let btn = createBtn || evt.currentTarget;
        let caller = btn.getAttribute('data-caller');
        let modalEl = document.getElementById('loginModal');

        let contact = this.#validateCreate(modalEl);

        // Clean the remaining fields.
        for ( let field in contact ) {
            if ( field !== 'messages' ) {
                if ( contact.hasOwnProperty(field) ) {
                    let domField = modalEl.querySelector('[name="' + field + '"]');
                    let domError = domField.parentElement.nextElementSibling;
                    domError.innerHTML = '';
                    domError.classList.add('hidden');
                }
            }
        }

        if ( contact.messages !== undefined && contact.messages !== null ) {
            for ( let field in contact.messages ) {
                if ( contact.messages.hasOwnProperty(field) ) {
                    let domField = modalEl.querySelector('[name="' + field + '"]');
                    let domError = domField.parentElement.nextElementSibling;

                    domError.innerHTML = contact.messages[field];
                    domError.classList.remove('hidden');
                }
            }
            try {
                evt.preventDefault();
                return false;
            }
            catch (err) {
                return false;
            }
        }

        let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        axios.post(route('account.create'),
                   {
                       first_name: contact.first_name,
                       last_name: contact.last_name,
                       email: contact.contact_email,
                       password: contact.password,
                       password_confirmation: contact.password_confirmation,
                       timezone: userTimezone,
                       caller: caller,
                   })
            .then((res) => {
                let data = res.data;
                if ( data.Status === 'Success' ) {

                    // The user needs to verify their email address.
                    if ( data.Result.view !== undefined ) {
                        let modalEl = document.getElementById('loginModal');
                        let brModal = BRModal.getInstance(modalEl);
                        brModal.setTitle('Verify Email');
                        brModal.setBody(data.Result.view);

                        addListener('click', '#emailResend', () => this.sendEmailVerification());

                        // Set the saved icon in the screen.
                        if (caller !== null) {
                            let productId = caller.split('_').pop();
                            document.querySelector('[data-id="' + productId + '"]').classList.add('text-ops-goldenrod-500');
                        }
                    }
                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(function(error) {

                if ( error.response !== undefined ) {
                    if ( error.response.status === 422 ) {
                        // Clean old errors first.
                        let first_name = modalEl.querySelector('[name="first_name"]');
                        let last_name = modalEl.querySelector('[name="last_name"]');
                        let contact_email = modalEl.querySelector('[name="contact_email"]');
                        let password = modalEl.querySelector('[name="password"]');

                        let errors = error.response.data.errors;
                        for ( let field in errors ) {
                            if ( errors.hasOwnProperty(field) ) {
                                switch ( field ) {
                                    case 'first_name':
                                        first_name.parentElement.nextElementSibling.innerHTML = errors[field][0];
                                        first_name.parentElement.nextElementSibling.classList.remove('hidden');
                                        break;
                                    case 'last_name':
                                        last_name.parentElement.nextElementSibling.innerHTML = errors[field][0];
                                        last_name.parentElement.nextElementSibling.classList.remove('hidden');
                                        break;
                                    case 'email':
                                        contact_email.parentElement.nextElementSibling.innerHTML = errors[field][0];
                                        contact_email.parentElement.nextElementSibling.classList.remove('hidden');
                                        break;
                                    case 'password':
                                        password.parentElement.nextElementSibling.innerHTML = errors[field][0];
                                        password.parentElement.nextElementSibling.classList.remove('hidden');
                                        break;
                                }
                            }
                        }
                    }
                    else {
                        console.log(error);
                    }
                }
                else {
                    console.log(error);
                }
            });
    }

    #validateCreate(container)
    {
        let firstName = container.querySelector('#first_name');
        let lastName = container.querySelector('#last_name');
        let email = container.querySelector('#contact_email');
        let password = container.querySelector('[type="password"][name="password"]');
        if (password === null)
            password = container.querySelector('[type="text"][name="password"]');
        let passwordConfirmation = container.querySelector('[type="password"][name="password_confirmation"]');
        if (passwordConfirmation === null)
            passwordConfirmation = container.querySelector('[type="text"][name="password_confirmation"]');
        let progress = container.querySelector('#passwordStrength');

        let contact = {};
        let message = {};

        if ( firstName.value !== '' ) {
            contact.first_name = firstName.value;
        }
        else {
            message['first_name'] = 'Please enter your first name.';
        }

        if ( lastName.value !== '' ) {
            contact.last_name = lastName.value;
        }
        else {
            message['last_name'] = 'Please enter your last name.';
        }

        if ( email.value !== '' ) {
            contact.contact_email = email.value;
        }
        else {
            message['contact_email'] = 'Please enter your email address.';
        }

        if ( password.value !== '' ) {
            if ( parseInt(progress.getAttribute('data-score')) < 3 ) {
                message['password'] = 'The password requires more strength.';
            }
            else {
                contact.password = password.value;
            }
        }
        else {
            message['password'] = 'Please enter a password.';
        }

        if ( passwordConfirmation.value !== '' ) {
            contact.password_confirmation = passwordConfirmation.value;
        }
        else {
            message['password_confirmation'] = 'Please confirm your password.';
        }

        if ( Object.keys(message).length > 0 )
            contact.messages = message;

        return contact;
    }

    showPassword(evt)
    {
        let btn = evt.currentTarget;
        let passwordInput = btn.previousElementSibling.previousElementSibling;
        let toggleIcon = btn.children[0];
        let newSlash = '<i class="cursor-pointer fa-sharp fa-light fa-eye-slash"></i>';
        let newEye = '<i class="cursor-pointer fa-sharp fa-light fa-eye"></i>';

        if (passwordInput.type === "password") {
            passwordInput.type = "text";
            toggleIcon.replaceWith(htmlToElement(newSlash));
        }
        else {
            passwordInput.type = "password";
            toggleIcon.replaceWith(htmlToElement(newEye));
        }

        passwordInput.focus();
    }

    validatePasswordStrength(evt)
    {
        let input = evt.currentTarget;

        if ( input.getAttribute('name') === 'password' ) {

            function scaleNumber(input, minInput, maxInput, minOutput, maxOutput) {
                return Math.round(((input - minInput) / (maxInput - minInput)) * (maxOutput - minOutput) + minOutput );
            }

            function setColorLines(number) {
                for (let y=0; y < 12; y++) {

                    let yColor = 'bg-ops-gunmetal/30';
                    if (y >= 0 && y <= 3) {
                        yColor = 'bg-ops-danger';
                    } else if (y >= 4 && y <= 7) {
                        yColor = 'bg-ops-goldenrod-400';
                    } else if (y >= 8 && y <= 11) {
                        yColor = 'bg-ops-success';
                    }

                    if (y < number) {
                        // Set it's color
                        progress.children[y].classList.remove('bg-ops-gunmetal/30');
                        progress.children[y].classList.add(yColor);
                    }
                    else {
                        // Set the bg to gray
                        progress.children[y].classList.remove(yColor);
                        progress.children[y].classList.add('bg-ops-gunmetal/30');
                    }
                }
            }

            function analyzePassword(value)
            {
                const hasLowerCase = /[a-z]/.test(value);
                const hasUpperCase = /[A-Z]/.test(value);
                const hasNumber = /\d/.test(value);
                const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value); // Modify the symbol set as needed

                let lcContainer = document.getElementById('passLCs');
                let ucContainer = document.getElementById('passUCs');
                let numContainer = document.getElementById('passNum');
                let symContainer = document.getElementById('passSym');
                let cntContainer = document.getElementById('passCharCnt');

                function updateClasses(element, ...classesToAddOrRemove) {
                    classesToAddOrRemove.forEach((cls, idx) => {
                        if (idx % 2 === 0) {
                            if (cls !== '')
                                element.classList.remove(cls);
                        } else {
                            if (cls !== '')
                                element.classList.add(cls);
                        }
                    });
                }

                let newCheck = '<i class="fa-sharp fa-solid fa-check text-ops-success"></i>';
                let newX = '<i class="fa-sharp fa-solid fa-x text-ops-danger text-[90%]"></i>';

                // Lower Case
                if (hasLowerCase) {
                    lcContainer.children[0].replaceWith(htmlToElement(newCheck));
                } else {
                    lcContainer.children[0].replaceWith(htmlToElement(newX));
                }

                // Upper Case
                if (hasUpperCase) {
                    ucContainer.children[0].replaceWith(htmlToElement(newCheck));
                } else {
                    ucContainer.children[0].replaceWith(htmlToElement(newX));
                }

                // Number
                if (hasNumber) {
                    numContainer.children[0].replaceWith(htmlToElement(newCheck));
                } else {
                    numContainer.children[0].replaceWith(htmlToElement(newX));
                }

                // Symbol
                if (hasSymbol) {
                    symContainer.children[0].replaceWith(htmlToElement(newCheck));
                } else {
                    symContainer.children[0].replaceWith(htmlToElement(newX));
                }

                // Length
                cntContainer.textContent =  value.length + '/8 Characters';
            }

            // Update the strength of the password
            let result = zxcvbn(input.value);
            let progress = document.querySelector('#passwordStrength');

            let perc = (result.guesses_log10 - 1) / (12 - 1);
            if ( perc > 1 ) perc = 1;

            let num100 =  Math.round((perc * 100));
            let scaledNumber = scaleNumber(num100, 1, 100, 1, 12);

            setColorLines(scaledNumber);

            analyzePassword(input.value);
        }
    }

    sendEmailVerification()
    {
        axios.post(route('verification.send'),
                   {})
            .then((res) => {
                let data = res.data;
                if ( data.Status === 'Success' ) {

                    // The user needs to verify their email address.
                    if ( data.Result.view !== undefined ) {
                        let modal = document.getElementById('loginModal');
                        modal.querySelector('.modalTitle').innerHTML = 'Verify Email';
                        modal.querySelector('.modalBody').innerHTML = data.Result.view;

                        addListener('click', '#emailResend', () => this.sendEmailVerification());
                        addListener('click', '#createOver', () => this.createAccount());
                    }
                    else {
                        // Close the modal
                        let loginModal = document.getElementById('loginModal');
                        BRModal.getInstance(loginModal).hide();
                    }
                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(function(error) {

                if ( error.response !== undefined ) {

                    console.log(error);
                }
            });
    }

    ignoreLogin(e)
    {
        let btn = e.target.data.btn;
        axios.get(route('login.ignore'), {params: {}})
            .then(function(res) {
                let data = res.data;
                if ( data.Status === 'Success' ) {

                    ProductAction.saveForLater.call(btn);

                    let loginModal = document.getElementById('loginModal');
                    let modal = BRModal.getInstance(loginModal);
                    modal.hide();
                }
                else {
                    showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    setSubmitLogin()
    {
        addListener('keyup', '#loginForm', function(e) {
            if ( e.keyCode === 13 ) {
                e.preventDefault();
                const userLogin = e.target.data.btn;
                userLogin.call(this.querySelector('#userLogin'));
            }
        });
    }

    static getInstance(authElement) {
        return AuthHeader.authInstances.get(authElement);
    }
}

export default AuthHeader