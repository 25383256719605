import { addListener } from "../../../../../../resources/js/utils";

class Footer
{
    constructor()
    {
        addListener('click', '#toggleHours', function() {
            if (document.getElementById('additionalHours').classList.contains('!hidden')) {
                this.querySelector('svg').setAttribute('data-fa-transform', 'rotate-180');
            }
            else {
                this.querySelector('svg').setAttribute('data-fa-transform', '');
            }

            document.getElementById('additionalHours').classList.toggle('!hidden');
        });
    }
}

export default Footer;