import {BRModal} from "@dbetts/brjstools";

const listenerRegistry = new WeakMap();

/**
 * We use a WeakMap registry to track event listeners to each DOM element.
 * When the element is replace via an AJAX call, the WeakMap will recognize the
 * new element is not the old element and will allow us to add the listener to the
 * new element.  *
 * This is done to help manage listeners when the DOM changes via AJAX calls to the server
 * and data is rendered in the browser with new elements.
 * @param event
 * @param elementSelector
 * @param execute
 * @param dataObject
 * @param options
 */
function addListener(event, elementSelector, execute, dataObject = null, options = false) {
    let el = typeof elementSelector === 'string'
        ? document.querySelectorAll(elementSelector)
        : elementSelector instanceof NodeList
            ? elementSelector
            : [elementSelector];

    el.forEach(element => {
        if (!listenerRegistry.has(element)) {
            listenerRegistry.set(element, []);
        }

        const listeners = listenerRegistry.get(element);
        const listenerExists = listeners.some(
            listener => listener.event === event && listener.originalExecute === execute
        );

        if (!listenerExists) {
            const boundExecute = execute.bind(null); // Store the bound function
            element.addEventListener(event, boundExecute, options);
            element.data = dataObject;

            listeners.push({ event, execute: boundExecute, originalExecute: execute, options, data: dataObject });
        }
    });
}

function removeListener(event, elementSelector, execute) {
    let el = typeof elementSelector === 'string'
        ? document.querySelectorAll(elementSelector)
        : elementSelector instanceof NodeList
            ? elementSelector
            : [elementSelector];

    el.forEach(element => {
        const listeners = listenerRegistry.get(element) || [];
        const listener = listeners.find(
            l => l.event === event && l.originalExecute === execute
        );

        if (listener) {
            element.removeEventListener(event, listener.execute, listener.options);
            listeners.splice(listeners.indexOf(listener), 1);
        }
    });
}


let AutocompleteController = new AbortController();

/*
 requestURI = '/api/search/' + this.active.q

 requestParams = {
 "page": this.active.page,
 "sortBy": this.active.sortBy,
 "filter": this.active.facets,
 }
 */
async function getAutocomplete(requestURI, requestParams)
{
    return new Promise(async(resolve, reject) => {

        // Abort any previous Autocomplete request
        AutocompleteController.abort();

        // Re-instantiate another instance of AbortController()
        AutocompleteController = new AbortController();

        axios.get(requestURI, {
            params: requestParams,
            signal: AutocompleteController.signal
        })
            .then(response => {
                return resolve(response.data); // this.payload = response.data
            })
            .catch(error => {
                // console.error(error)
                return reject(error)
            });
    });
}


function setSpinnerOff(reqNo)
{
    if ( typeof reqNo == 'string' && reqNo.toLowerCase() === 'all' ) {
        document.querySelectorAll('.spinner.on').forEach(function(spin) {
            spin.remove();
        });
    }
    else {
        document.querySelector('[data-id="' + reqNo + '"]').remove();
    }
}

function setSpinnerOn(reqNo)
{

    let spin = {
        setSpinner: function(reqNo) {
            if ( reqNo === undefined )
                reqNo = getRandomSpinId();

            let newSpinner = document.getElementById('spinnerContainer').cloneNode(true);
            newSpinner.setAttribute('data-id', reqNo);
            newSpinner.classList.remove('off');
            newSpinner.classList.add('on');
            document.querySelector('body').append(newSpinner);
        }
    };

    spin.setSpinner(reqNo);
}

function getRandomSpinId()
{
    let number = ((Math.random() * 100000) + 1) + '';
    let num = number.replace('.', '-');
    return 'SPIN-' + num;
}

function showAlert(message, title = 'Alert')
{
    const myModal = setModal();
    myModal.setTitle(title);
    myModal.setBody(message);
    myModal.setFooterButton(0, 'btn-primary', 'Okay');
    myModal.hideFooterButton(1);

    myModal.show();
}

function setModal()
{
    // Set the modal with the complete content
    const modalElement = document.querySelector('[data-br-modal="default"]').cloneNode(true);
    modalElement.setAttribute('id', 'SPIN_' + getRandomSpinId());
    const body = document.querySelector('body');
    body.appendChild(modalElement);

    return new BRModal(modalElement);
}

function popupToast(message, title, theme = 'default', seconds = 3)
{
    // Get the toast DIV
    let toast = document.getElementById("defaultToast");
    let toastTitle = toast.querySelector('.toastTitle');
    let toastMessage = toast.querySelector('.toastMessage');

    // Add the message
    toastMessage.innerHTML = '';
    toastMessage.append(htmlToElement(message));

    if ( title === undefined ) {
        toastTitle.innerHTML = '';
        toastTitle.parentElement.classList.remove('pb-2');
        toastMessage.classList.remove('pt-4');
        toastMessage.classList.remove('pb-4');
        toastMessage.classList.add('-mt-2');
        toastMessage.classList.add('x-sm:mt-0');
        toastMessage.classList.add('pb-7');
    }
    else {
        // Add the title
        toastTitle.innerHTML = title;
        toastTitle.parentElement.classList.add('pb-2');
        toastMessage.classList.add('pt-4');
        toastMessage.classList.remove('-mt-2');
        toastMessage.classList.remove('x-sm:mt-0');
        toastMessage.classList.remove('pb-7');
    }

    // Color theme - default = white bg
    switch ( theme ) {
        case 'default':
            toast.classList.remove('w-auto');
            toast.classList.add('w-96');

            toastTitle.parentElement.classList.remove('bg-ops-beaver');
            toastTitle.parentElement.classList.add('bg-white');

            toastMessage.classList.remove('bg-ops-beaver');
            toastMessage.classList.add('bg-white');

            toast.querySelector('[data-icon-path]').style.fill = '#E0AD29';
            break;
        case 'cart':
            toast.classList.remove('w-96');
            toast.classList.add('w-auto');

            toastTitle.parentElement.classList.remove('bg-white');
            toastTitle.parentElement.classList.add('bg-ops-beaver');

            toastMessage.classList.remove('bg-white');
            toastMessage.classList.add('bg-ops-beaver');

            toast.querySelector('[data-icon-path]').style.fill = '#FFF';
            break;
        default:
            break;
    }

    // Add the "show" class to DIV
    toast.setAttribute('data-toast-show', '');
    toast.removeAttribute('data-toast-hide');

    addListener('click', '[data-br-toast-dismiss]', function(e) {
        const toast = e.currentTarget.closest('#defaultToast');
        toast.setAttribute('data-toast-hide', '');
        toast.removeAttribute('data-toast-show');
    });

    // After 3 seconds, remove the data-toast-show attribute
    setTimeout(function() {
        toast.removeAttribute('data-toast-show');
        toast.setAttribute('data-toast-hide', '');
    }, seconds * 1000);
}

const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

function formatPhoneNumber(phoneValue)
{
    let value = phoneValue.replace(/\D/g, '');

    if ( value.length > 0 ) {
        if ( value.length > 10 ) {
            value = value.slice(0, 10);
        }
        if ( value.length > 6 ) {
            value = `(${ value.slice(0, 3) }) ${ value.slice(3, 6) }-${ value.slice(6) }`;
        }
        else if ( value.length > 3 ) {
            value = `(${ value.slice(0, 3) }) ${ value.slice(3) }`;
        }
    }

    return value;
}

function formatExpDate(expDate)
{
    let value = expDate.replace(/\D/g, '');

    if ( value.length > 0 ) {
        if ( value.length > 4 ) {
            value = value.slice(0, 4);
        }

        if ( value.length > 2 ) {
            value = `${ value.slice(0, 2) }/${ value.slice(2, 4) }`;
        }
    }

    return value;
}

/**
 * @param {String} html representing a single element
 * @return {Element}
 */
function htmlToElement(html)
{
    // Use DOMParser to parse the string into a Document
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Extract the element from the parsed document
    return doc.body.firstChild;


    /*let template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content;*/
}

/**
 * Returns all elements that were submitted in the HTML string.
 * @param html
 * @returns {*[]}
 */
function htmlToElements(html)
{
    // Use DOMParser to parse the string into a Document
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Extract the element from the parsed document
    const results = [];
    const children = doc.body.childNodes;
    for (let i = 0; i < children.length; i++) {
        results.push(children[i]);
    }
    return results;
}


function closeModal()
{
    let modal = this.data.modal;
    Modal.getInstance(modal).hide();
}

function buildQueryString(params, prefix = '')
{
    const queryParts = [];

    for (const [key, value] of Object.entries(params)) {
        // Skip undefined, null, or empty string values
        if ( value === undefined || value === null || value === '' || value.length === 0 || (typeof value === 'object' && Object.keys(value).length === 0) ) continue;

        if (typeof value === 'object') {
            queryParts.push(buildQueryString(value, prefix ? `${prefix}[${key}]` : key));
        } else {
            const encodedKey = encodeURIComponent(prefix ? `${prefix}[${key}]` : key);
            const encodedValue = encodeURIComponent(value);
            queryParts.push(`${encodedKey}=${encodedValue}`);
        }
    }

    return queryParts.join('&');
}

function brCollapse()
{
    let btn = this;
    let targetId = btn.getAttribute('data-br-collapse-target');

    let targetElement = document.querySelector(targetId);
    let isClosed = targetElement.classList.toggle('!hidden');

    if (btn.data) {
        if (isClosed) {
            btn.innerHTML = btn.data.closed;
        }
        else {
            btn.innerHTML = btn.data.open;
        }
    }
}

function setInputErrorNotice(element, message)
{
    let parent = element.parentElement;
    let errorMessage = document.createTextNode(message);

    let errorDiv = parent.querySelector('[data-te-input-helper-ref]');
    if (errorDiv === null) {
        errorDiv = document.createElement('div');
        errorDiv.setAttribute('class', 'text-red-500 text-sm');
        errorDiv.setAttribute('data-te-input-helper-ref', '');
        parent.appendChild(errorDiv);
    }
    else {
        errorDiv.classList.remove('hidden');
        parent.classList.remove('mb-3');
        parent.classList.add('mb-4');
    }

    element.classList.remove('border-slate-200');
    element.classList.add('border-red-500');
    errorDiv.innerHTML = '';
    errorDiv.appendChild(errorMessage);
}


export {
    addListener,
    removeListener,
    getAutocomplete,

    getRandomSpinId,
    setSpinnerOn,
    setSpinnerOff,

    currencyFormat,
    formatPhoneNumber,
    formatExpDate,
    htmlToElement,
    htmlToElements,
    showAlert,
    setModal,
    popupToast,
    closeModal,

    buildQueryString,

    brCollapse,
    setInputErrorNotice,
}