import '../../../../../resources/js/bootstrap';
import creditCardType                                 from "credit-card-type";
import { addListener, htmlToElement, removeListener } from "../../../../../resources/js/utils";
import route                                          from "ziggy-js";

function setToast()
{
    // Get the snackbar DIV
    let toast = document.getElementById("defaultToast");

    // Add the "show" class to DIV
    toast.setAttribute('data-toast-show', '');
    toast.removeAttribute('data-toast-hide');

    // After 3 seconds, remove the data-toast-show attribute
    setTimeout(function() {
        toast.removeAttribute('data-toast-show');
        toast.setAttribute('data-toast-hide', '');
    }, 3000);
}

function buildPill(attributeValue, attributeId)
{
    let span = document.createElement('span');
    let button = document.createElement('button');
    let svg = document.createElement('svg');
    let path = document.createElement('path');

    span.setAttribute('class', 'inline-block whitespace-nowrap rounded-full bg-ops-beaver px-2 py-1 mb-1 mr-1.5 ' +
        'text-center align-baseline text-[0.75em] font-bold leading-none text-ops-smoke');
    button.setAttribute('type', 'button');
    button.setAttribute('class', 'flex font-normal text-xs leading-3');
    svg.setAttribute('class', 'removeFilter mr-1.5');
    svg.setAttribute('style', 'height: 12px');
    svg.setAttribute('height', '1em');
    svg.setAttribute('viewBox', '0 0 384 512');
    svg.setAttribute('fill', 'currentColor');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('stroke-width', '1.5');
    svg.setAttribute('stroke', 'currentColor');
    svg.setAttribute('data-attribute-id', attributeId);
    path.setAttribute('d', 'M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z');

    svg.appendChild(path);
    button.appendChild(svg);
    button.appendChild(document.createTextNode(attributeValue));
    span.appendChild(button);


    return span.outerHTML;
}

function setCartHeaderData(total, items)
{
    document.querySelectorAll('.summaryTot').forEach(function(tot) {
        tot.innerHTML = total;
    });
    let cartCount = document.getElementById('shoppingCartList');
    cartCount.children[0].innerHTML = Object.keys(items).length + '';
    let theUL = document.querySelector(cartCount.getAttribute('data-br-target'));

    for ( let i in items ) {
        if ( items.hasOwnProperty(i) ) {
            theUL.append(buildHeaderCartItem(items[i]));
        }
    }
}
function setSavedHeaderMarker(hasSaved)
{
    let cartEl = document.getElementById('shoppingCartList');
    let nextSibling = cartEl.parentElement.parentElement;
    try {
        if ( hasSaved ) {
            // If nextSibling is the first child of the parent element, add a new dom element
            if ( nextSibling === nextSibling.parentElement.firstElementChild ) {
                let isLight = cartEl.previousElementSibling.classList.contains('!border-ops-border');
                const a = document.createElement('a');
                let ltClass = isLight ? 'text-ops-night' : 'text-white';
                let aClass = ltClass + ' text-3xl x-sm:text-2xl whitespace-nowrap cursor-pointer hover:text-ops-primary';
                a.setAttribute('class', aClass);
                a.setAttribute('href', route('products.save-compare'));

                const i = document.createElement('i');
                i.setAttribute('class', 'fa-sharp fa-light fa-bookmark');
                i.setAttribute('title', 'Save for later');

                a.appendChild(i);

                nextSibling.parentElement.insertBefore(a, nextSibling);
            }
        }
        else {
            // If we have a saved items marker, remove it.
            if ( nextSibling !== nextSibling.parentElement.firstElementChild ) {
                nextSibling.parentElement.removeChild(nextSibling.parentElement.firstElementChild);
            }
        }
    }
    catch (err) {
        console.log(err);
    }
}


function setLoginHeaderData(authWidget)
{
    document.getElementById('accountList').replaceWith(htmlToElement(authWidget));
}

function buildHeaderCartItem(item)
{
    let li = document.createElement('li');
    let a = document.createElement('a');
    a.setAttribute('class', 'block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700' +
        ' hover:bg-neutral-100 active:text-neutral-800 active:no-underline' +
        ' disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400');
    a.setAttribute('href', route('products.view', {slug: item.slug}) + '?pid=' + item.id);
    a.setAttribute('data-te-dropdown-item-ref', '');
    a.innerHTML = item.name;
    li.append(a);

    return li;
}

function ccCardTypeObserve(ccNumberElement)
{
    // Set the left margin. ccNumber must be fetched from the DOM again.
    const labelElement = ccNumberElement.nextElementSibling;
    setCcLabelLeft( labelElement );

    // Create an instance of MutationObserver
    const observer = new MutationObserver(function(mutations, observer) {
        mutations.forEach(function(mutation) {
            if ( mutation.type === 'attributes' && mutation.attributeName === 'class' ) {

                const oldClassValue = mutation.oldValue.split(' ');
                const currentClassValue = mutation.target.className.split(' ');
                const addedClasses = currentClassValue.filter(className => !oldClassValue.includes(className));
                const removedClasses = oldClassValue.filter(className => !currentClassValue.includes(className));

                if ( addedClasses.length || removedClasses.length ) {
                    setCcLabelLeft( mutation.target );
                }
            }
        });
    });
    // Start observing the target element
    observer.observe(labelElement, {attributes: true, attributeFilter: ['class'], attributeOldValue: true});
}

function setCreditCardType(e)
{
    let ccInput = e.currentTarget;
    let response = creditCardType(ccInput.value);
    let cardType = response.length > 0 ? response[0].type : 'cc-blank';

    ccInput.parentElement.classList.remove('cc-blank');
    ccInput.parentElement.classList.remove('visa');
    ccInput.parentElement.classList.remove('mastercard');
    ccInput.parentElement.classList.remove('discover');
    ccInput.parentElement.classList.remove('american-express');

    ccInput.parentElement.classList.add(cardType);

    document.querySelector('[name="cc_type"]').value = cardType;
}

function setCcLabelLeft( labelElement )
{
    if ( labelElement.classList.contains('has-value') ) {
        labelElement.classList.remove('!left-10');
        labelElement.classList.add('!left-3');
    }
    else {
        labelElement.classList.remove('!left-3');
        labelElement.classList.add('!left-10');
    }
}

function animateSelect(elementId, container)
{
    // We contain the instances in an object for access later, if needed. This should be setup as a global variable
    //  for that purpose.
    /*let animateSelectInstances = {};

    container.querySelectorAll('.form-animate').forEach(function(animator) {
        if (animator.children[0] !== undefined && animator.children[0].classList.contains('select')) {

            if ( animator.querySelector('ul.listbox[id="'+elementId+'"]') !== null ) {

                animateSelectInstances[elementId] = {
                    animator: animator,
                    container: animator.querySelector(".select"),
                    button: animator.querySelector("[data-animate-select-button]"),
                    button_label: animator.querySelector("[data-animate-select-label]"),
                    options: animator.querySelectorAll("ul.listbox li.list-option")
                };

                function openDd()
                {
                    // add/remove active class on the container element
                    animateSelectInstances[elementId].container.classList.toggle("active");
                    // update the aria-expanded attribute based on the current state
                    animateSelectInstances[elementId].button.setAttribute(
                        "aria-expanded",
                        animateSelectInstances[elementId].button.getAttribute("aria-expanded") === "true" ? "false" : "true"
                    );
                }

                // Clear any listeners first
                let newSelectBtn = animateSelectInstances[elementId].button.cloneNode(true);
                animateSelectInstances[elementId].button.parentNode.replaceChild(newSelectBtn, animateSelectInstances[elementId].button);
                animateSelectInstances[elementId].button = animator.querySelector("[data-animate-select-button]");
                // add a click event to select button
                addListener('click', animateSelectInstances[elementId].button, openDd);

                animateSelectInstances[elementId].options.forEach((option) => {
                    function handler(e)
                    {
                        if (e.pointerId > 0) {

                            // Click Events
                            if ( e.type === "click" && e.clientX !== 0 && e.clientY !== 0 ) {
                                // Uncheck everything
                                this.parentElement.querySelectorAll('input[type="radio"]').forEach(function(radio){radio.checked=false});

                                let textContainer = animateSelectInstances[elementId].animator.querySelector("[data-animate-select-label]");
                                textContainer.textContent = this.children[1].textContent;
                                this.children[0].checked = true;

                                animateSelectInstances[elementId].container.classList.remove("active");
                                animateSelectInstances[elementId].container.nextElementSibling.nextElementSibling.classList.add('has-value');
                            }
                            // Key Events
                            if ( e.key === "Enter" ) {
                                // Uncheck everything
                                this.parentElement.parentElement.querySelectorAll('input[type="radio"]').forEach(function(radio){radio.checked=false});

                                let textContainer = animateSelectInstances[elementId].animator.querySelector("[data-animate-select-label]");
                                textContainer.innerHTML = this.textContent;
                                this.previousElementSibling.checked = true;

                                animateSelectInstances[elementId].container.classList.remove("active");
                                animateSelectInstances[elementId].container.nextElementSibling.nextElementSibling.classList.add('has-value');
                            }

                            // Create the event with current information
                            const createChangeEvent = new CustomEvent('br.select.change', {
                                detail: {
                                    element: this.children[0],
                                    opener: this.parentElement,
                                }
                            });
                            // Fire the animate.br.select event
                            this.parentElement.dispatchEvent(createChangeEvent);
                        }
                    }

                    // Clear any listeners first
                    removeListener('keyup', option, handler);
                    removeListener('click', option, handler);

                    // Add the listeners
                    addListener('keyup', option, handler);
                    addListener('click', option, handler);
                });
            }
        }
    });*/

    const uls = container.querySelectorAll('[data-animate-select]');
    if (uls.length > 0) {
        for (let i = 0; i < uls.length; i++) {

            if ( uls[i].getAttribute('id') === '_' + elementId ) {
                const ul = uls[i];

                if ( ul.parentElement !== undefined && ul.parentElement.classList.contains('select') ) {

                    const container = ul.parentElement;
                    const button = ul.previousElementSibling;
                    const button_label = ul.previousElementSibling.querySelector("[data-animate-select-label]");
                    const options = ul.querySelectorAll("li.list-option");
                    const hiddenPlaceholder = container.parentElement.querySelector('input[name="' + elementId + '"]');
                    // Flag to track if the last interaction was via mouse
                    let isMouseInteraction = false;

                    // add a click event to select button
                    addListener('click', button, handleClick);
                    addListener('focus', button, handleFocus);
                    addListener('mousedown', button, handleMouseDown);
                    addListener('keydown', button, handleKeyDown);

                    // We add a change event to the hidden placeholder for cases when the name="" is populated by the browser.
                    // addListener('input', hiddenPlaceholder, findSetOption);
                    addListener('change', hiddenPlaceholder, findSetOption);

                    options.forEach(option => {

                        // Clear any listeners first
                        removeListener('input', option, handler);
                        removeListener('click', option, handler);

                        // Add the listeners
                        addListener('input', option, handler);
                        addListener('click', option, handler);
                    });

                    function handler(e)
                    {
                        const clicked = e.currentTarget;

                        // Click Events
                        if ( e.type === "click" && e.clientX !== 0 && e.clientY !== 0 ) {
                            // Uncheck everything
                            clicked.parentElement.querySelectorAll('input[type="radio"]').forEach(function(radio) {
                                radio.checked = false
                            });

                            hiddenPlaceholder.value = clicked.children[0].value;

                            button_label.textContent = clicked.children[1].textContent;
                            clicked.children[0].checked = true;

                            container.classList.remove("active");
                            container.nextElementSibling.nextElementSibling.classList.add('has-value');
                        }
                        // Key Events
                        if ( e.key === "Enter" ) {
                            // Uncheck everything
                            clicked.parentElement.parentElement.querySelectorAll('input[type="radio"]').forEach(function(radio) {
                                radio.checked = false
                            });

                            hiddenPlaceholder.value = clicked.children[0].value;
                            let textContainer = container.querySelector("[data-animate-select-label]");
                            textContainer.innerHTML = clicked.textContent;
                            clicked.previousElementSibling.checked = true;

                            container.classList.remove("active");
                            container.nextElementSibling.nextElementSibling.classList.add('has-value');
                        }

                        // Create the event with current information
                        const createChangeEvent = new CustomEvent('br.select.change', {
                            detail: {
                                element: clicked.children[0],
                                opener : clicked.parentElement,
                            }
                        });

                        // Fire the animate.br.select event
                        clicked.parentElement.dispatchEvent(createChangeEvent);
                    }

                    function handleClick(e)
                    {
                        openDd(e);
                    }

                    function handleFocus(e)
                    {
                        if ( !isMouseInteraction ) {
                            // Open dropdown only if focus was via keyboard
                            openDd(e);
                        }
                        // Reset the flag
                        isMouseInteraction = false;
                    }

                    function handleMouseDown(e)
                    {
                        isMouseInteraction = true;
                    }

                    function handleKeyDown(e)
                    {
                        if ( e.key === 'Tab' || e.keyCode === 9 ) {
                            // If Tab key is pressed, set flag indicating keyboard navigation
                            isMouseInteraction = false;
                        }
                    }

                    function openDd(e)
                    {
                        // add/remove active class on the container element
                        container.classList.toggle("active");
                        // update the aria-expanded attribute based on the current state
                        button.setAttribute(
                            "aria-expanded",
                            button.getAttribute("aria-expanded") === "true" ? "false" : "true"
                        );
                    }

                    // Close the dropdown when clicking outside
                    document.addEventListener('click', function(event) {
                        if ( !button.contains(event.target) && !container.contains(event.target) ) {
                            container.classList.remove('active');
                        }
                    });

                    // Close the dropdown when pressing Escape key
                    document.addEventListener('keydown', function(event) {
                        if ( event.key === 'Escape' || event.keyCode === 27 ) {
                            container.classList.remove('active');
                            // Remove focus from button
                            button.blur();
                        }
                    });

                    function findSetOption(e)
                    {
                        const newValue = e.currentTarget.value;
                        const selectInputs = ul.querySelectorAll("li.list-option input");

                        for ( const input of selectInputs ) {
                            if ( input.value === newValue || input.nextElementSibling.textContent === newValue ) {
                                handler({
                                            currentTarget: input.parentElement,
                                            type         : "click",
                                            clientX      : 1,
                                            clientY      : 1,
                                            key          : 'blank',
                                        });
                                break;
                            }
                        }
                    }
                }
            }
        }
    }
}

function animateSelectSetValue(container, newValue)
{
    // Uncheck everything first.
    container.querySelectorAll('input[type="radio"]').forEach(function(radio) {
        radio.checked = false
    });

    // Set the new value
    let radio = container.previousElementSibling.querySelector('input[value="' + newValue + '"]');
    radio.checked = true;

    // Test the value
    let newLabel = radio.nextElementSibling.textContent;
    if ( newValue === '' ) {
        newLabel = '&nbsp;';
        container.nextElementSibling.classList.remove('has-value');
    }
    else {
        container.nextElementSibling.classList.add('has-value');
    }

    // Set the button text
    container.previousElementSibling.children[0].innerHTML = newLabel;

    // Set the hidden input
    // const selectName = container.getAttribute('id');
    // let selectBox = document.querySelector('[name="' + selectName + '"]');
    container.value = newValue === '&nbsp;' ? '' : newValue;
}

function animateSelectGetValue(container)
{
    let newValue = '';
    container.querySelectorAll('input[type="radio"]').forEach(radio => {
        if ( radio.checked ) {
            newValue = radio.value;
        }
    });

    return newValue;
}

function animateInput(elementId, container, isTextarea=false)
{
    const element = container.querySelector('#'+elementId);

    if (element) {

        // Initialize
        const actionElement = element.parentElement.querySelector('label');
        if ( element.value ) {
            actionElement.classList.add('has-value');
        }
        else {
            actionElement.classList.remove('has-value');
        }

        function setClass(e)
        {
            let actionElement = e.currentTarget.parentElement.querySelector('label');

            if ( e.currentTarget.value ) {
                actionElement.classList.add('has-value');
            }
            else {
                actionElement.classList.remove('has-value');
            }
        }

        // Clear any listeners first
        // let newElement = element.cloneNode(true);
        // element.parentNode.replaceChild(newElement, element);
        // Set listener for changes
        removeListener('input', element, setClass);
        addListener('input', element, setClass);
    }
}

function animateInputSetValue(container, newValue)
{
    let actionElement = container.parentElement.querySelector('label');
    container.value = newValue
    container.setAttribute('value', newValue);

    if ( newValue !== '' ) {
        actionElement.classList.add('has-value');
    }
    else {
        actionElement.classList.remove('has-value');
    }
}

function displayFieldValidation(fieldsObject)
{
    if ( fieldsObject.messages !== undefined && fieldsObject.messages !== null ) {
        for ( let field in fieldsObject.messages ) {
            if ( fieldsObject.messages.hasOwnProperty(field) ) {
                let domField = document.querySelector('[name="' + field + '"]');
                let domError = undefined;
                if ( domField.getAttribute('type') === 'hidden' ) {
                    // This is a select animate
                    domError = domField.nextElementSibling.nextElementSibling;
                }
                else {
                    domError = domField.parentElement.nextElementSibling;
                }
                domError.innerHTML = fieldsObject.messages[field];
                domError.classList.remove('hidden');
            }
        }

        for ( let field in fieldsObject ) {
            if ( field !== 'messages' ) {
                if ( fieldsObject.hasOwnProperty(field) ) {
                    let domField = document.querySelector('[name="' + field + '"]');
                    let domError = undefined;
                    if ( domField.getAttribute('type') === 'hidden' ) {
                        // This is a select animate
                        domError = domField.nextElementSibling.nextElementSibling;
                    }
                    else {
                        domError = domField.parentElement.nextElementSibling;
                    }
                    domError.innerHTML = '';
                    domError.classList.add('hidden');
                }
            }
        }

        return false;
    }
    else {
        // Clear the fields.
        for ( let field in fieldsObject ) {
            if ( field !== 'messages' ) {
                if ( fieldsObject.hasOwnProperty(field) ) {
                    let domField = document.querySelector('[name="' + field + '"]');
                    let domError = undefined;
                    if ( domField.getAttribute('data-select-value') !== null ) {
                        // This is a select animate
                        domError = domField.nextElementSibling.nextElementSibling;
                    }
                    else {
                        domError = domField.parentElement.nextElementSibling;
                    }
                    domError.innerHTML = '';
                    domError.classList.add('hidden');
                }
            }
        }

        return true;
    }
}


export {
    setToast,
    ccCardTypeObserve,
    setCreditCardType,
    buildPill,
    setCartHeaderData,
    setSavedHeaderMarker,
    setLoginHeaderData,
    animateSelect,
    animateSelectGetValue,
    animateSelectSetValue,
    animateInput,
    animateInputSetValue,
    displayFieldValidation,
}